<template>
  <div
    id="footer-main"
    class="relative bg-blue-900 text-white w-full"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="pageSalesFunnel"
      class="mx-auto relative -top-7 -mb-7 max-w-8xxl lg:px-14 xl:px-20 px-4"
    >
      <div
        class="flex items-center justify-center gap-2 bg-blue-900 rounded-t-lg font-bold h-7 w-[5.75rem] text-center text-sm cursor-pointer"
        @click="toggleLegal"
      >
        Legal

        <IconsChevron
          fill="#ffffff"
          :direction="showLegal ? 'top' : 'bottom'"
          size="xl"
          class="w-3 h-3"
        />
      </div>
    </div>

    <div
      class="w-full max-w-wrapper-lg mx-auto relative px-4 xl:px-0 pt-6 md:pt-8 pb-10"
      :class="{
        'h-0 overflow-hidden !px-0 !pt-0 md:!pt-0 !pb-0': !showLegal && pageSalesFunnel
      }"
    >
      <nuxt-img
        loading="lazy"
        width="189"
        height="32"
        class="img-responsive mb-5 md:mb-6"
        src="/images/cc-logo-white-text.svg"
        alt="Compare Club"
      />

      <template v-if="(!pageSalesFunnel && showLegal) || (!pageSalesFunnel)">
        <ul class="flex flex-wrap mb-4 font-bold text-xs xl:text-sm">
          <li
            v-for="(item, itemKey) in VERTICAL_MENU"
            :key="itemKey"
            class="flex leading-[2] md:leading-none"
          >
            <nuxt-link :to="item.path">
              {{ item.name }}
            </nuxt-link>

            <span
              v-if="(itemKey + 1) !== VERTICAL_MENU.length"
              class="mx-1 md:mx-4"
            >
              |
            </span>
          </li>
        </ul>

        <div class="flex flex-col gap-[0.875rem] lg:gap-4">
          <ul class="flex flex-wrap text-blue-40 font-bold text-xs xl:text-sm break-words">
            <li
              v-for="(item, itemKey) in SUB_MENU"
              :key="itemKey"
              class="flex items-center leading-[2] md:leading-none"
            >
              <nuxt-link
                :to="item.path"
                :target="item.target"
              >
                {{ item.name }}
              </nuxt-link>

              <span
                v-if="(itemKey + 1) !== SUB_MENU.length"
                class="mx-1 md:mx-4"
              >
                |
              </span>
            </li>
          </ul>

          <ul class="flex gap-2 text-sm">
            <li
              v-for="(item, itemKey) in SOCIALS"
              :key="itemKey"
              class="flex items-center"
            >
              <a
                v-show="(item.mobileOnly) ? devices.isMobile.value : true"
                target="_blank"
                class="flex items-center"
                rel="noopener"
                :href="item.path"
                width="24"
                height="24"
              >
                <NuxtImg
                  :width="item.width"
                  :height="item.height"
                  :src="`/icons/social/${item.name}.svg`"
                  :alt="item.name"
                  loading="lazy"
                />
              </a>
            </li>
          </ul>
        </div>

        <hr class="border-0 border-b-gray-10 border-b-1 lg:opacity-40 my-6 lg:mt-[1.625rem]" />
      </template>

      <div
        :class="{
          'flex flex-col md:flex-row justify-start gap-[0.875rem] lg:gap-9': pageSalesFunnel
        }"
      >
        <div
          class="flex flex-col text-[0.625rem] gap-[0.875rem] lg:gap-6 lg:text-xs"
          :class="{
            'flex-1': pageSalesFunnel
          }"
          v-html="disclaimer"
        >
        </div>

        <a
          v-if="pageVertical === 'health-insurance'"
          href="/about-us/"
          target="_blank"
          class="inline-block min-w-20 min-h-[3.625rem]"
          :class="{
            'mt-6': !pageSalesFunnel
          }"
        >
          <nuxt-img
            width="80"
            height="58"
            src="https://asset.compareclub.com.au/images/phi-code-of-conduct-logo.svg"
            alt="Private Health Insurance Intermediaries Association (PHIIA) Code of Conduct"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  VERTICAL_MENU,
  SUB_MENU,
  SOCIALS,
  DISCLAIMER_MAP
} from '~/constants/footer.constants'

defineOptions({
  name: 'OrganismsFooter'
})

const layoutsStore = useLayoutsStore()

const $route = useRoute()
const path = $route.path

const { devices } = deviceChecker()

/**
 * modify vertical in state
 */
const vertical = computed(() => {
  if ($route.path.includes('health-insurance') || $route.path.includes('post-sale-thank-you')) {
    return VERTICAL.HEALTH_INSURANCE
  }

  if ($route.path.includes('life-insurance')) {
    return VERTICAL.LIFE_INSURANCE
  }

  if ($route.path.includes('energy')) {
    return VERTICAL.ENERGY
  }

  if ($route.path.includes('personal-loans')) {
    return VERTICAL.PERSONAL_LOANS
  }

  if ($route.path.includes('credit-cards')) {
    return VERTICAL.CREDIT_CARDS
  }

  if ($route.path.includes('home-loans')) {
    return VERTICAL.HOME_LOANS
  }

  if ($route.path.includes('car-loans')) {
    return VERTICAL.CAR_LOANS
  }

  if ($route.path.includes('car-insurance')) {
    return VERTICAL.CAR_INSURANCE
  }

  return ''
})

watch(() => vertical, val => {
  layoutsStore.vertical = val.value
}, {
  deep: true,
  immediate: true
})

const currVertical = computed(() => {
  return $route.path.includes('/partners/signup')
    ? ''
    : vertical?.value || VERTICAL.DEFAULT
})

const pageVertical = computed(() => {
  if (
    currVertical.value &&
    (
      currVertical.value === VERTICAL[strToSnakeCase(currVertical.value).toUpperCase()] ||
      path.includes(currVertical.value)
    )
  ) {
    return strToKebabCase(currVertical.value)
  }

  return 'default'
})

const pageSalesFunnel = computed(() => !!pageIsFunnel($route))

const pageType = computed(() => pageSalesFunnel.value ? 'funnel' : 'default')

const disclaimer = computed(() => {
  const disclaimerObj = getKey(pageType.value, DISCLAIMER_MAP)

  return getKey(pageVertical.value, disclaimerObj) || DISCLAIMER_MAP.default[pageVertical.value]
})

const showLegal = ref(false)

async function toggleLegal () {
  showLegal.value = !showLegal.value

  if (showLegal.value) {
    await wait(1)
    window.scrollTo({ top: document.body.scrollHeight })
  }
}
</script>
